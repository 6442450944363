#login a  ,#forgetPsw a{
  text-decoration: none !important;
  display: flex;
  align-items: center;
}

#login a span , #forgetPsw a span {
  font-weight: bold;
  font-size: 25px;
}

#login , #forgetPsw {
  width: 100%;
  min-height: 100vh;
}

/* #login .input-group-text {
  background-color: white !important;
  border-left: none !important ;
} */

#login .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #7367f0 !important;
}

/* #login .input-group.input-group-merge .form-control:focus {
  border-left: 1px solid #7367f0 !important;
  border-top-left-radius: auto !important;
} */

#login .login-logo , #forgetPsw .login-logo {
  position: absolute;
  top: 1.3rem;
}

#login .login-logo img , #forgetPsw .login-logo img {
  width: 145px;
  /* height: 145px; */
}

#login .center-form , #forgetPsw .center-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#login .row-length , #forgetPsw .row-length {
  min-height: 100vh;
}
label {
  font-size: 1rem !important;
  color: #5e5873;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.google-icon:hover{
  background-color: rgba(245, 113, 113,0.2) !important;
  
}
.google-icon{
  border-radius: 5px !important;
  cursor: pointer;
  width: 3rem;
}
