.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.css-16xfy0z-control {
    background-color: transparent !important;
}

.css-wsp0cs-MultiValueGeneric {

    padding: 6px !important;
}


h6 {

    margin: 0px !important;
}

.css-16xfy0z-control {

    border-style: hidden !important;

}

/*  */