.psw .input-group-text {
  border-right: 1px solid lightgray !important;
}

.psw .input-group-merge .form-control {
  padding: 0.438rem 1rem !important;
  border-right: none;
  border-left: 1px solid lightgray !important;
}
.vertical li {
  padding: 6px 0px;
}

.tab-content {
  width: 100%;
}

.vertical span{
  font-size:  0.925rem !important;
}

.spanClass {
  font-size: 14px;
  letter-spacing: 0.9px;
}

.nav-link.active {
  padding: 12px 15px;
}

#account-setting .input-group.input-group-merge .form-control {
  background-color: white !important;
}

#account-setting .input-group.input-group-merge {
  max-width: 100%;
}

#account-setting
  .input-group:not(.bootstrap-touchspin):focus-within
  .input-group-text {
  border-color: #7367f0 !important;
}

#account-setting .input-group.input-group-merge .form-control:focus {
  border-left: 1px solid #7367f0 !important;
  border-top-left-radius: auto !important;
}

.alert {
  padding: 0px !important;
}

.rc-time-picker-input {
  height: 35px !important;
}

.row.mb-3 label {
  margin-bottom: 0px;
}
.react-switch {
  vertical-align: middle;
  margin-left: 10px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}

.row.align-items-center.mb-3 .col-md-3 label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 1px;
}

.row.align-items-center.mb-3 .col-md-2 p {
  font-weight: 700;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
}

.operatingHours .row.align-items-center.mb-3 {
  height: 50px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #0062cc !important;
}

.operatingHours select {
  border: none;
  border-bottom: 2px solid;
  border-radius: 0px;
  border-color: #0062cc !important;
}

.operatingHours select:focus {
  box-shadow: none !important;
  border-width: 3px;
}
.styles-module_embedPreviewFooter__26Gb3{
  display: none !important;
}